@font-face {
    font-family: 'Formula1';
    src: url('./assets/fonts/Formula1-Regular.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'Formula1', Arial, sans-serif;
    background-color: #111;
    color: #fff;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    font-family: 'Formula1', Arial, sans-serif;
    cursor: pointer;
}